import React from 'react'
import {graphql} from 'gatsby'
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry'

import {
  PageHeader,
  MetaData,
  Layout,
  ShowcaseCard,
  CTASingleButton,
} from '../components'
import {BoxedContainer, Section} from '../elements'

const ShowcasePage = ({data}) => {
  const posts = data.showcasePosts.edges

  return (
    <Layout>
      <MetaData data={data.currentPage} />
      <PageHeader
        title="Showcase"
        subtitle="A couple of our friends who are into our themes."
      />
      <Section padding="both">
        <BoxedContainer>
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 1280: 3}}
          >
            <Masonry gutter="60px">
              {posts.map(post => (
                <ShowcaseCard post={post.node} key={post.node.id} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </BoxedContainer>
      </Section>
      {/* <CTASingleButton
        title="Submit Your Site"
        link="mailto:support@stylesheets.dev?subject=Submit%20to%20Showcase&body=What%20is%20your%20website's%20link%3F%0A%0A%0AWhich%20theme%20are%20you%20using%3F%0A%0A%0AAny%20other%20comments%3F%0A"
      /> */}
    </Layout>
  )
}

export default ShowcasePage

export const query = graphql`
  {
    currentPage: ghostPage(slug: {eq: "showcase"}) {
      ...GhostMetaPageFields
    }

    showcasePosts: allContentfulShowcasePost {
      edges {
        node {
          ...ContentfulShowcasePostFields
        }
      }
    }
  }
`
